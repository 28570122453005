<template>
  <div id="module-box">
    <div class="bg-color-black">
      <div class="top">
        <div class="d-flex pt-2 pl-2">
          <span>
            <span class="iconfont icontongji text-icon"></span>
          </span>
          <div class="d-flex">
            <span class="fs-xl text mx-2">各站点气温概况</span>
            <dv-decoration-3 class="dv-dec-3" />
          </div>
        </div>
        <!-- <div class="show-more">
          <span class="text">查看更多</span>
          <img src="@/assets/next.svg" alt="" />
        </div> -->
      </div>
      <div class="d-flex p-me-box">
        <e-instrument-panel
          v-for="(data, index) in temperatureRegionData"
          v-bind:key="index"
          :cdata="data"
        ></e-instrument-panel>
      </div>
    </div>
  </div>
</template>

<script>
import EInstrumentPanel from "@/components/echart/instrumentPanel";
export default {
  data() {
    return {
      temperatureRegionWidth: "130px",
      temperatureRegionHeight: "140px",
      temperatureRegionData: null,
    };
  },
  components: {
    EInstrumentPanel,
  },
  mounted() {},
  methods: {},
  props: {
    soureTemperatureData: Array,
  },
  watch: {
    soureTemperatureData: {
      handler(newData) {
        if(newData==null) return;
        this.temperatureRegionData = newData.map((item) => {
          item.width = this.temperatureRegionWidth;
          item.height = this.temperatureRegionHeight;
          return item;
        });
        this.temperatureRegionData.splice(3,this.temperatureRegionData.length)
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 190px;

#module-box {
  padding: 0 16px 0 16px;
  width: $box-width;
  border-radius: 10px;

  .text {
    color: #c3cbde;
    font-size: 14px;
  }
  .bg-color-black {
    height: $box-height;
    border-radius: 10px;
    .top {
      display: flex;
      justify-content: space-between;
      //查看更多
      .show-more {
        cursor: pointer;
        .text {
          color: #85e7e7;
        }
        img {
          width: 20px;
          height: 20px;
          position: relative;
          bottom: -5px;
        }
      }
    }
  }

  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .p-me-box {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0;
    .item-box {
      width: 120px;
      margin-bottom: 5px;
    }
  }
}
</style>
