<template>
  <div id="module-box">
    <div class="bg-color-black">
      <div class="top">
        <div class="d-flex pt-2 pl-2">
          <span class="iconfont icontongji text-icon"></span>
          <div class="d-flex">
            <span class="fs-xl text mx-2">{{ siteName }}实况</span>
            <dv-decoration-3 class="dv-dec-3" />
          </div>
        </div>
        <!-- <div class="show-more">
          <span class="text">查看更多</span>
          <img src="@/assets/next.svg" alt="" />
        </div> -->
      </div>
      <div class="p-me-box">
        <zk-meteorological-index
          v-for="(item, index) in realTimeUpdateData"
          :key="index"
          :meImg="item.meImg"
          :meTitle="item.meTitle"
          :meValue="item.meValue"
          :meUnit="item.meUnit"
        ></zk-meteorological-index>
      </div>
    </div>
  </div>
</template>

<script>
import ZkMeteorologicalIndex from "@/components/meteorologicalIndexStatistics";
export default {
  data() {
    return {
      realTimeUpdateData: this.realTimeData,
      // showIndexs: [
      //   "风力等级",
      //   "风速",
      //   "体感温度",
      //   "气压",
      //   "湿度",
      //   "云量",
      //   "风向",
      //   "温度",
      // ],
      showIndexs: [
        "风速",
        "大气压",
        "湿度",
        "风向",
        "温度",
        "辐射强度",
        "日照时数",
        "蒸散发"
      ],
      siteName:''
    };
  },
  components: {
    ZkMeteorologicalIndex,
  },
  props: ["realTimeData"],
  watch: {
    realTimeData: {
      handler(newData) {
        if(newData==null) return 
        this.siteName = newData.siteName;
        this.realTimeUpdateData = newData.monitoringIndex
          .filter((item) => this.showIndexs.includes(item.indexName))
          .map((item) => {
            let unit = item.indexUnit;
            if(item.indexName=="蒸散发"){
              unit = 'mm/h';
            }
            return {
              meImg: `${require("@/assets/visual/" + item.indexName + ".svg")}`,
              meTitle: item.indexName,
              meValue: item.indexValue,
              meUnit: unit,
            };
          });
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 410px;

#module-box {
  padding: 0 16px 0 16px;
  width: $box-width;
  border-radius: 10px;

  .text {
    color: #c3cbde;
    font-size: 14px;
  }
  .bg-color-black {
    // height: $box-height - 30px;
    border-radius: 10px;
    .top {
      display: flex;
      justify-content: space-between;
      //查看更多
      .show-more {
        cursor: pointer;
        .text {
          color: #85e7e7;
        }
        img {
          width: 20px;
          height: 20px;
          position: relative;
          bottom: -5px;
        }
      }
    }
  }

  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .p-me-box {
    display: grid;
    padding: 15px 0;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 42px);
    grid-gap: 20px 0; //行间距 列间距
  }
}
</style>
