<template>
  <div id="pre-box">
    <div>
      <div class="pre-chart bg-color-black">
        <div class="d-flex pt-2 pl-2">
          <span class="iconfont icontongji text-icon"></span>
          <div class="d-flex">
            <span class="fs-xl text mx-2">辐射量</span>
            <dv-decoration-3 class="dv-dec-3" />
          </div>
        </div>
        <!-- 辐射量统计图 -->
        <Echart
          :options="options"
          id="radiationIntensity-analyze"
          :height="radiationIntensityHeight"
          :Width="radiationIntensityWidth"
        ></Echart>
      </div>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      radiationIntensityWidth: "100%",
      radiationIntensityHeight: "270px",
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    radiationIntensityData: Object,
  },
  mounted() {},
  watch: {
    radiationIntensityData: {
      handler(newData) {
        if (newData == null) return;
        this.init(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    init(newData) {
      this.options = {
        //你的代码
        backgroundColor: "transparent",
        grid: {
          bottom: "20",
        },
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                // color: "#788eb0",
                color: "#4DB6C0",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#788eb0",
              },
            },
            data: newData.xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "W/㎡",
            nameTextStyle: {
              fontSize: 14,
              padding: [0, 0, 0, -30],
            },
            min: 0,
            splitLine: {
              show: false,
              lineStyle: {
                color: "#2A2C2A",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#4DB6C0",
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            symbol: "circle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#2af7ff",
                lineStyle: {
                  color: "#2af7ff",
                  width: 3,
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "RGBA(42, 247, 255, 0.2)",
                    },
                    {
                      offset: 1,
                      color: "RGBA(42, 247, 255, 0.8)",
                    },
                  ]),
                },
              },
            },
            data: newData.yAxisData,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 310px;

#pre-box {
  padding: 0 16px;
  // height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    height: $box-height;
    border-radius: 10px;
    margin-bottom: 16px;
  }
  .text {
    color: #c3cbde;
    font-size: 14px;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
}
</style>
