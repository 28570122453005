<template>
  <div id="mapDiv"></div>
</template>
<script>
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import mapConfig from "@/config/map.js";
import {degreesConversion} from "../../utils/index"
export default {
  data() {
    return {
      map: null,
      minZoom: 15,
      maxZoom: 21,
      zoom: 20,
      tilesBounds: mapConfig.tilesBounds,
      tilesUrl: mapConfig.tilesUrl,
      accessToken: mapConfig.accessToken,
      sensor: require("@/assets/visual/传感器1.png"),
      popupConfig: {
        closeOnClick: false,
        focusAfterOpen: true,
        closeButton: false,
        anchor: "bottom",
        className: "tipDiv",
      },
      mapSourceData: null,
      sensorGeoJson: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      },
      lngOffset: 0.000245,
      latOffset: 0.00029,
      sites: [],
      showIndexs: ["湿度", "风速", "风向", "降水量", "太阳辐射"],
      isMapInit: false,
    };
  },
  props: ["mapIndexData"],
  watch: {
    mapIndexData: {
      handler(newData) {
        if (newData == null) return;
        this.sites = Object.values(newData);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      mapboxgl.accessToken = this.accessToken;
      //天地图（各个区域的token可以在网上查到）
      //实例化Map地图对象
      if (!this.isMapInit) {
        this.initMapDoc();
      }
      // 设置地图为中文简体
      this.map.addControl(
        new MapboxLanguage({
          defaultLanguage: "zh-Hans",
        })
      );
      // Add a scale control to the map
      // this.map.addControl(new mapboxgl.ScaleControl())
      this.map.on("load", () => {
        // 添加3d地形栅格图层
        this.map.addSource("mapbox-dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          tileSize: 512,
        });
        this.map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
        // 添加自己的切片图层
        let newBounds = this.tilesBounds.split(",").map((item) => Number(item));
        this.map.addSource("Marking", {
          type: "raster",
          tiles: [this.tilesUrl],
          bounds: newBounds,
        });
        this.map.addLayer({
          id: "Marking",
          type: "raster",
          source: "Marking",
          tiles: [this.tilesUrl],
          layout: {
            visibility: "visible",
          },
        });
        // 平移和缩放地图以包含其指定地理范围内的可见区域
        this.map.fitBounds(
          [
            [newBounds[0], newBounds[1]], // southwestern corner of the bounds
            [newBounds[2], newBounds[3]], // northeastern corner of the bounds
          ],
          {
            pitch: 65, //倾斜角度
            // bearing: 120.98, //旋转角度
            bearing: -30, //旋转角度
            // offset: [60.249398380125939, 20.6888144039216101],
          }
        );
      });
      // 渲染地图上的传感器信息
      this.renderdingSensorPos();
    },
    initMapDoc() {
      this.map = new mapboxgl.Map({
        //地图容器div的id
        container: "mapDiv",
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        preserveDrawingBuffer: true,
        //地图中心点
        center: [102.2056845, 31.7921859],
        //地图当前缩放级数
        // zoom: this.zoom,
        zoom: this.zoom,
        // maxZoom: this.maxZoom,
        maxZoom: 21,
        minZoom: this.minZoom,
        pitch: 85,
        bearing: 120.98,
      });
      this.isMapInit = true;
    },
    renderdingSensorPos() {
      // 渲染sites数据
      this.sites.forEach((site) => {
        // 添加气象数据源
        // /* let radarSourceName = 'radar-' + index;
        // this.map.addSource(radarSourceName, {
        //   type: "image",
        //   url: require(`@/assets/atmosphere/${site.weather}.png`),
        //   coordinates: this.getWeatherImgPosition(site.position),
        // });
        // this.map.addLayer({
        //   id: `radar-layer-${index}`,
        //   type: "raster",
        //   source: radarSourceName,
        //   paint: {
        //     "raster-fade-duration": 0,
        //   },
        // }); */
        // 添加站点位置到geojson
        this.sensorGeoJson.data.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: site.position,
          },
        });
        // 添加提示框
        // 拼接提示框内容html
        // TODO
        let imgUrl = "设备智能";
        let contentHtml = `<div class='item9'><div class='top-title'><div style='background: center/contain url("${require(`@/assets/visual/${imgUrl}.png`)}") no-repeat;'></div><span class='title'>${
          site.siteName
        }</span></div><ul class='data-box'>`;
        site.monitoringIndex.forEach((monItem) => {
          if (this.showIndexs.includes(monItem.indexName)) {
            if(monItem.indexName=="风向"){
              monItem.indexValue = degreesConversion(Number(monItem.indexValue));
              monItem.indexUnit = ""
            }
            contentHtml += `<li>${monItem.indexName}：${monItem.indexValue}${
              monItem.indexUnit ? monItem.indexUnit : ""
            }</li>`;
          }
        });
        contentHtml += "</ul></div>";
        new mapboxgl.Popup(this.popupConfig)
          .setLngLat(site.position)
          .setHTML(contentHtml)
          .addTo(this.map);
      });
      this.map.loadImage(this.sensor, (error, image) => {
        if (error) throw error;
        // Add the image to the map style.
        this.map.addImage("sensor", image);
        // Add a data source containing one point feature.
        this.map.addSource("point", this.sensorGeoJson);
        // Add a layer to use the image to represent the data.
        this.map.addLayer({
          id: "points",
          type: "symbol",
          source: "point", // reference the data source
          layout: {
            "icon-image": "sensor", // reference the image
            "icon-size": 1.25, // 图片显示大小比列
          },
          minzoom: 14, //缩放等级小于11被隐藏
        });
      });
    },
    getWeatherImgPosition: function (centerPoint) {
      // 根据中心坐标点计算图片四个角坐标
      // 横纬竖经  维度0.00058（/2=0.00029）  经度0.00049 (/2) = 0.000245
      let lngTop = centerPoint[0] + this.lngOffset;
      let lngBottom = centerPoint[0] - this.lngOffset;
      let latTop = centerPoint[1] + this.latOffset * 2;
      // let latBottom = centerPoint[1]-this.latOffset;
      return [
        [lngBottom, latTop],
        [lngTop, latTop],
        [lngTop, centerPoint[1]],
        [lngBottom, centerPoint[1]],
      ];
    },
  },
};
</script>
<style lang="scss">
#mapDiv {
  width: 100%;
  height: 100%;
}

.mapboxgl-popup-content {
  background: transparent;
  box-shadow: none;
  padding: 5px;
  background: linear-gradient(to left, #2cd5ff, #2cd5ff) left top no-repeat,
    linear-gradient(to bottom, #2cd5ff, #2cd5ff) left top no-repeat,
    linear-gradient(to left, #2cd5ff, #2cd5ff) right top no-repeat,
    linear-gradient(to bottom, #2cd5ff, #2cd5ff) right top no-repeat,
    linear-gradient(to left, #2cd5ff, #2cd5ff) left bottom no-repeat,
    linear-gradient(to bottom, #2cd5ff, #2cd5ff) left bottom no-repeat,
    linear-gradient(to left, #2cd5ff, #2cd5ff) right bottom no-repeat,
    linear-gradient(to left, #2cd5ff, #2cd5ff) right bottom no-repeat;
  background-size: 2px 15px, 15px 2px, 2px 15px, 15px 2px;
  border: 1px solid #073f97;
  position: relative;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: rgb(53, 137, 182, 0.6);
}

.item9 {
  background-color: rgba(19, 35, 60, 0.4);
  color: #90e1f4;
  font-size: 22px;

  .top-title {
    display: flex;
    align-items: center;

    div:first-child {
      width: 30px;
      height: 30px;
      // background: center/contain url("~@/assets/visual/device-position.svg") no-repeat;
    }

    span {
      transform: scale(0.8);
    }
  }

  .data-box {
    // width: 100%;
    user-select: none;
    font-size: 14px;
    padding: 0 5px 0 16px;

    li {
      text-indent: -3px;
      /*该属性取值可为负值，距离缩小*/
      list-style-type: disc;
    }
  }
}

.mapboxgl-control-container {
  display: none;
}
</style>
