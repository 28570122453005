<template>
  <div class="outer-box">
    <Echart
      :options="options"
      id="precipitation-analyze"
      height="200px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        if (newData == null) return;
        // this.width = newData.width;
        // this.height = newData.height;
        this.options = {
          // 背景色 默认透明色 设置了主题颜色的话 显示的就是主题颜色
          backgroundColor: "transparent",
          grid: {
            top: "10%",
            left: "3%",
            right: "3%",
            bottom: "3%",
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            confine: true,
            position: function (pt) {
              return [pt[0], "10%"];
            },
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false, // 不显示坐标轴刻度线
            },
            axisLabel: {
              textStyle: {
                color: "#777E86",
              },
            },
            data: newData.xAxisData,
          },
          yAxis: {
            splitLine: {
              //网格线
              lineStyle: {
                color: "#2A2C2A",
              },
            },
            axisLabel: {
              textStyle: {
                color: "#777E86",
              },
            },
            axisLine: {
              show: true, // 不显示坐标轴线
            },
            axisTick: {
              show: false, // 不显示坐标轴刻度线
            },
            type: "value",
          },
          series: [
            {
              data: newData.yAxisData,
              type: "line",
              areaStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgb(255, 255, 255,0.01)",
                      },
                      {
                        offset: 1,
                        color: "rgb(0, 0, 0, 1)",
                      },
                    ],
                    false
                  ),
                },
              },
              lineStyle: {
                //折线的样式
                color: "#919291",
              },
              itemStyle: {
                //折线拐点标志的样式
                borderColor: "#8BD9EC", //拐点的边框颜色
                // borderWidth: 1.0,
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
