<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <!-- 日期 -->
        <div class="date-box">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</div>
        <!-- 标题开始 -->
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">{{ titleText }}</span>
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>
        <!-- 标题结束 -->
        <div class="body-box">
          <!-- 地图开始 -->
          <div class="map-screen">
            <dv-border-box-8 class="box-8" :dur="15">
              <zk-map :mapIndexData="mapIndexData" />
            </dv-border-box-8>
          </div>
          <!-- 地图结束 -->
          <div class="content-box">
            <div
              class="left-box"
              style="height: calc(100% - 40px); overflow: hidden"
            >
              <dv-border-box-12 class="left-border">
                <!-- 实时指标统计 -->
                <zk-real-time-metrics
                  class="m-20"
                  :realTimeData="realTimeData"
                />
                <!-- 降水量统计 -->
                <zk-precipitation class="m-20" :precipitationData="preData" />
                <!-- 各区域温度统计 -->
                <zk-temperature-by-region
                  :soureTemperatureData="temperatureRegionData"
                  class="m-20"
                />
                <!-- 24小时大气温湿度 -->
                <zk-atmospheric-temperature-humidity
                  :echartData="temperatureHumidityData"
                />
              </dv-border-box-12>
            </div>
            <!-- 右侧统计图 -->
            <div
              class="right-box"
              style="height: calc(100% - 45px); overflow: hidden"
            >
              <dv-border-box-10
                class="r-box-10"
                :color="['#2e6099', '#7ce7fd']"
              >
                <!-- 辐射量统计 -->
                <zk-radiation-intensity
                  :radiationIntensityData="radiationIntensityData"
                />
                <!-- 风力风向概况 -->
                <zk-wind-statistics :windData="windData" />
                <!-- 土壤温湿度 -->
                <zk-soil-analysis class="right-1" :echartData="temperatureHumidityData" />
              </dv-border-box-10>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDataByMapId,
  getRealTimeData,
  get24Hours,
  getPreWeekData
} from "../api/sensorData";
import {degreesConversion} from "../utils/index"
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
import ZkPrecipitation from "./leftmodule/precipitation";
import ZkRealTimeMetrics from "./leftmodule/realTimeMetrics";
import ZkTemperatureByRegion from "./leftmodule/temperatureByRegion";
import ZkAtmosphericTemperatureHumidity from "./leftmodule/atmosphericTemperatureHumidity";
import ZkMap from "./centermodule/map";
import ZkWindStatistics from "./rightmodule/windStatistics";
import ZkRadiationIntensity from "./rightmodule/radiationIntensity";
import ZkSoilAnalysis from "./rightmodule/soilAnalysis";

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
      titleText: "猫坪微气象大屏",
      userConfigData: [],
      siteName: "",
      realTimeData: null,
      preData: null,
      temperatureRegionData: null,
      temperatureHumidityData: null,
      mapIndexData: null,
      radiationIntensityData: null,
      windData: null,
      initIndex: 0,
      sitePosition: null,
      isfirstInit: true,
      evaporationData: null,
    };
  },
  components: {
    ZkPrecipitation,
    ZkRealTimeMetrics,
    ZkMap,
    ZkTemperatureByRegion,
    ZkAtmosphericTemperatureHumidity,
    ZkRadiationIntensity,
    ZkWindStatistics,
    ZkSoilAnalysis,
  },
  async created() {
    await this.init();
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
    this.changeDeviceData();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    async init(iotid) {
      const form = new FormData();
      form.append("mapId", '1775466761715912706');
      form.append("tenantId", '1775444157340782593');
      form.append("iotId", iotid ? iotid : null);
      const dataForm = new FormData();
      dataForm.append("iotId", iotid ? iotid : null);
      await getDataByMapId(form)
        .then((response) => {
          const { sitePosition } = response.data.data;
          this.sitePosition = sitePosition;
          getRealTimeData(dataForm).then((res) => {
            const { regionRealTimeData, temperatureRegionData } = res.data.data;
            regionRealTimeData
            this.realTimeData = regionRealTimeData[this.sitePosition];
            this.$store.commit('SET_DEVICE_ID', this.realTimeData.deviceId);
            regionRealTimeData[this.sitePosition].monitoringIndex.forEach(item => {
              if(item.indexName=="风向"){
                item.indexValue = degreesConversion(Number(item.indexValue))
                item.indexUnit = ""
              }
            }); 
            this.temperatureRegionData = temperatureRegionData;
            this.mapIndexData = regionRealTimeData;
          });
          get24Hours(dataForm).then((res) => {  
            const {
              index24HoursData: {
                xAxisData,
                yAxisData: {
                  Radiation,
                  WindDirection,
                  WindSpeed,
                  Humidity,
                  Temperature,
                  Vwc
                }
              },
            } = res.data.data;
            this.temperatureHumidityData = {
              xAxisData: xAxisData,
              tempeData: Temperature,
              humData: Humidity,
            };
            this.radiationIntensityData = {xAxisData,'yAxisData':Radiation};
            this.evaporationData = {xAxisData,'yAxisData':Vwc};
            this.windData = {
              xAxisData: xAxisData,
              yAxisWinSpeed: WindSpeed,
              yAxisWinDire: WindDirection,
            };
          });

          getPreWeekData(dataForm).then((res) => {
            const { preData:{
              weekData:{
                yAxisData: wyAxisData,
              }
            } } = res.data.data;
            let preData = res.data.data.preData;
            preData.sitePre = Number(preData.sitePre).toFixed(2);
            preData.maxPre = Math.max(...wyAxisData).toFixed(2);
            preData.minPre = Math.min(...wyAxisData).toFixed(2);
            preData.hisUnit = "mm/h";
            this.preData = preData;
          });
        })
    },
    changeDeviceData() {
      // 10*60*1000
      setInterval(() => {
        let keysArr = Object.keys(this.mapIndexData);
        this.initIndex == keysArr.length - 1
          ? (this.initIndex = 0)
          : this.initIndex++;
        let currentData = this.mapIndexData[keysArr[this.initIndex]];
        let iotId = currentData?.deviceId;
        this.sitePosition = keysArr[this.initIndex];
        this.init(iotId);
      }, 10 * 60 * 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/home.scss";

#mapDiv {
  width: 100%;
  height: 100%;
}

.date-box {
  position: absolute;
  right: 16px;
  top: 25px;
  font-size: 18px;
}

.m-20 {
  margin-bottom: 20px;
}

.content-box .left-border {
  padding: 16px 0;
}

// .content-box .r-box-10 {
//   padding: 16px 0;
// }

.box-8 {
  padding: 5px;
}

.right-box {
  width: 27.77777777%;
  position: absolute;
  top: 0;
  right: 0;
  .right-1 {
    max-height: 420px;
    min-height: 200px;
  }
}
.map-screen {
  width: 100%;
  height: calc(100% - 40px);
  position: absolute;
  left: 0;
}
// .r-box-10{
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
// }
// .r-box-10 .border-box-content{
//   padding-top: 5px;
// }

.body-box {
  position: relative;
}
</style>
