<template>
  <div class="outer-box">
    <Echart
      :options="options"
      id="instrument-panel"
      :height="height"
      :width="width"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {};
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
  watch: {
    cdata: {
      handler(newData) {
        this.height = newData.height;
        this.width = newData.width;
        this.init();
        this.options.series[0].data[0].value = newData.value;
        this.options.series[0].data[0].name = newData.name;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    init: function () {
      this.options = {
        grid: {},
        tooltip: {
          formatter: "{a} <br/>{b} : {c}℃",
        },
        series: [
          {
            name: "气温",
            type: "gauge",
            splitNumber: 5, //仪表盘分割段数
            radius: "100%",
            startAngle: 192, //仪表盘开始角度
            endAngle: -12, //仪表盘结束角度
            // 仪表盘详情
            detail: {
              valueAnimation: true,
              formatter: "{value}℃",
              color: "#00E4FF",
              fontWeight: "bold",
              lineHeight: 16,
              fontSize: 20,
            },
            data: [
              {
                value: 50,
                name: "SCORE",
              },
            ],
            // 仪表盘轴线相关配置
            axisLine: {
              // 仪表盘轴线样式
              lineStyle: {
                width: 5,
              },
            },
            // 仪表盘进度条相关配置
            progress: {
              show: true,
              width: 5,
              itemStyle: {
                color: "#00E4FF",
              },
            },
            // 分割线样式
            splitLine: {
              show: true,
              length: 5,
              distance: 0,
            },
            // 刻度样式
            axisTick: {
              // show: true,
              // 刻度线与轴线的距离
              distance: 0,
              splitNumber: 4, //分割线之间分割的刻度数
            },
            // 刻度标签样式
            axisLabel: {
              show: true,
              how: true,
              color: "rgba(176, 238, 231, 1)",
              distance: 6,
              // formatter: "{value} ℃",
              fontSize: 10,
            },
            // 仪表盘指针
            pointer: {
              show: true,
              // offsetCenter: ["0", -10],
              width: 4.5,
              length: "78%",
              itemStyle: {
                color: "rgba(86, 192, 178, 1)",
              },
            },
            // 仪表盘标题
            title: {
              show: true,
              offsetCenter: ["0", "90%"],
              color: "#00FDFA",
            },
          },
        ],
      };
    },
  },
};
</script>
