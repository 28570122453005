<template>
  <!-- 区域各气象指标 -->
  <div class="item-box">
    <span class="img">
      <img :src="meImg" class="item-img" alt="" />
    </span>
    <div class="title-box">
      <div class="title">{{ meTitle }}</div>
      <div class="value">
        <span>{{ meValue }}</span>
        <span>{{ meDesc }}</span>
        <span>{{ meUnit }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // meImg 图片路径 meTitle 统计标题 meValue 统计结果 meDesc标题补充内容-区县/站点 meUnit 单位
  props: ["meImg", "meTitle", "meValue", "meDesc", "meUnit"],
};
</script>
<style lang="scss">
.item-box {
  height: 46px;
  display: flex;
  .img {
    width: 42px;
    height: 46px;
    background: #393a39;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    .item-img {
      width: 30px;
      height: 30px;
    }
  }
  .title-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 16px;
    }
    .value {
      span {
        height: 20px;
      }
      span:nth-child(0) {
        font-size: 12px;
      }
      span:nth-child(1) {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
}
</style>
